import { Box, Flex, FormControl, HStack, Image, Link, Switch, Text, UseDisclosureReturn } from '@chakra-ui/react';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import ToolbarButton from 'src-new/components/Toolbar/ToolbarButton';
import { useFrontierAuth } from 'src-new/util/auth/useFrontierAuth';
import logo from '../../assets/frontier-logo-new.png';
import { AnnouncementBox } from '../announcments/components/AnnouncementBox';
import { NavMenus } from './NavMenus';
import { useBetaStateStore } from 'src-new/stores/betaStateStore';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { QueryStatus } from './QueryStatus';

export const NavBar = (props: { joinWorkflowDisclosure: UseDisclosureReturn; isInWorkflow: boolean }) => {
  const auth = useFrontierAuth();
  const betaState = useBetaStateStore();

  return (
    <Box
      backgroundColor={'white'}
      height={'70px'}
      marginBottom={'1px'}
      borderTopWidth={{ base: '5px', lg: '10px', xl: '15px' }}
      borderTopColor={'primary.500'}
      borderStyle={'solid'}
      boxSizing='content-box'
      boxShadow={'0px 2px 4px 0px #80808054'}>
      <Flex
        backgroundColor={'white'}
        w={'100%'}
        h={'100%'}
        alignItems={'center'}
        gap={5}
        justifyContent={'space-between'}>
        <Box paddingLeft={'20px'}>
          <Link href='/'>
            <Image height={['40px']} src={logo} alt='Frontier Lodging Solutions' />
          </Link>
        </Box>
        <HStack>
          <QueryStatus />
          {auth?.user?.EnableWorkflowSystem && !props.isInWorkflow && (
            <Box fontSize={'small'}>
              <ToolbarButton onClick={props.joinWorkflowDisclosure.onOpen} icon={faUserGroup}>
                Join Workflow
              </ToolbarButton>
            </Box>
          )}
          {auth?.isInRole([ApplicationRoles.obtAgentBooking]) && (
            <Box>
              <FormControl display={'flex'} flexDir={'row'} gap={'2px'}>
                <Switch
                  size={'sm'}
                  id='email-alerts'
                  isChecked={betaState.enableBeta}
                  onChange={(e) => {
                    betaState.setEnableBeta(e.target.checked);
                  }}
                />
                <Text m={0} p={0} fontSize={'small'}>
                  Beta
                </Text>
              </FormControl>
            </Box>
          )}
        </HStack>

        <Box marginLeft={'auto'}>
          <NavMenus />
        </Box>
        <Box></Box>
        <Box h={'100%'} w={'400px'}>
          {auth.isAuthenticated && <AnnouncementBox />}
        </Box>
      </Flex>
    </Box>
  );
};
