import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type BetaStateStore = {
  enableBeta: boolean;
  setEnableBeta: (enable: boolean) => void;
};

export const useBetaStateStore = create(
  persist<BetaStateStore>(
    (set) => ({
      enableBeta: false,
      setEnableBeta: (isEnabled) => set({ enableBeta: isEnabled })
    }),
    {
      name: 'beta-state'
    }
  )
);
