import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { useEffect, useState } from 'react';
import { ApplicationRoles } from '../../constants/applicationRoles';

export const FrontierAccessDenied = () => {
  const auth = useAuth();
  const [isObtRedirect, setObtRedirect] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(11);

  useEffect(() => {
    if (auth.isInRole([ApplicationRoles.obtUser, ApplicationRoles.obtSuperUser, ApplicationRoles.obtAgentBooking])) {
      setObtRedirect(true);
      setTimeout(() => {
        if (window.location.host.indexOf('localhost') != -1) {
          window.location.href = 'https://localhost:3001/hotel-search';
        } else if (window.location.host.indexOf('cksoftware') != -1) {
          const parts = window.location.host.split('.');
          if (parts.length > 2) {
            parts.splice(1, 0, 'obt');
            const newUrl = parts.join('.');
            window.location.href = `https://${newUrl}/hotel-search`;
          }
        } else if (window.location.host == 'database.frontierlodging.com') {
          window.location.href = 'https://booking.frontierlodging.com/hotel-search';
        }
      }, 10000);
      setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }
  }, [auth.user]);

  return (
    <Flex w={'100%'} alignItems={'center'} marginTop={'16px'} flexDir={'column'}>
      <Box textAlign={'center'}>
        {!isObtRedirect && (
          <>
            <Heading>Access Denied</Heading>
            <Text>Sorry, you do not have the required roles to view this page.</Text>
          </>
        )}
        {isObtRedirect && (
          <>
            <Heading>Frontier Online Redirect Warning</Heading>
            <Text>
              You have been routed to an area outside of Frontier Online with a Frontier Online account. <br />
              You will be redirected back to Frontier Online in {remainingTime} seconds. <br />
            </Text>
          </>
        )}
      </Box>
    </Flex>
  );
};
